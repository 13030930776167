
































































import { Vue, Component } from 'vue-property-decorator';
import {
  BImg, BRow, BCol, BButton, BForm, BCard, BCardTitle, BCardText, BSpinner,
} from 'bootstrap-vue'
// INTERFACES
import { MSALBasic } from '../interfaces/MSALBasic';
// TYPES
import { isLoading } from '../types/generalTypes';
import { User } from '../types/userTypes';
// OTHERS
import LocalStorageManager from '../helper/LocalStorageManager';
import { APP_KEYWORDS } from '../enums';

@Component({
  components: {
    BButton,
    BForm,
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BImg,
    BSpinner,
  }
})
export default class Login extends Vue {
  
  // DATA
  public companyLogo: String = require('@/assets/logo_dark.png');
  public isLoading: isLoading = false;
  public $msal: MSALBasic;
  public localStorageManager = new LocalStorageManager();
  public user: User = {
    name: '',
    accessToken: '',
    email: ''
  }
  
  // METHODS
  async userLogin() {
    //this.$msal.signIn();

    const origin  = new URL(document.URL).origin
    var loginUrl=new URL(`https://login.microsoftonline.com/medloopus.com/oauth2/v2.0/authorize?`);
    loginUrl.searchParams.append('client_id','2282c401-d7fb-4012-b870-fb1c007896c9');
    loginUrl.searchParams.append('scope','user.read openid profile offline_access');
    loginUrl.searchParams.append('redirect_uri',`${origin}/LoginRedirect`);
    loginUrl.searchParams.append('response_mode','query');
    loginUrl.searchParams.append('response_type','code');
    loginUrl.searchParams.append('sso_reload','true');

    location.href =loginUrl.toString();
  }
  userLogout() {
    //this.$msal.signOut();
  }

  // CREATED
  // async created() {
  //   this.isLoading = true;
  //   if(this.$msal.isAuthenticated()) {
  //     await this.$msal.acquireToken().then((res:any) => {
  //         if (!res.accessToken) {
  //             this.$router.go(0);
  //         } else {
  //           this.user = {
  //             name: this.$msal.data.user.name,
  //             accessToken: res.accessToken,
  //             email: this.$msal.data.user.userName
  //           }
  //           this.localStorageManager.set(APP_KEYWORDS.LOCAL_STORAGE_KEY, this.user);
  //           this.isLoading = false;
  //         }
  //     });
  //   } else {
  //     this.localStorageManager.clearItem(APP_KEYWORDS.LOCAL_STORAGE_KEY);
  //     this.isLoading = false;
  //   }
  // }
}
