var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-page" },
    [
      _c(
        "b-row",
        { staticClass: "auth-v1 px-2" },
        [
          _c(
            "b-col",
            {
              staticClass: "mx-auto auth-inner py-2",
              attrs: { md: "4", sm: "9", xs: "9" },
            },
            [
              _c("b-card", { staticClass: "mb-0" }, [
                _c(
                  "div",
                  { staticClass: "w-100 text-center mt-1 mb-1" },
                  [
                    _c("b-img", {
                      attrs: { src: _vm.companyLogo, alt: "logo" },
                    }),
                  ],
                  1
                ),
                _c("br"),
                _vm.isLoading
                  ? _c(
                      "span",
                      [
                        _c(
                          "b-card-text",
                          { staticClass: "mb-2 text-center" },
                          [
                            _vm._v(
                              "\n            Please wait while we sign you in using your Office 360 account!\n            "
                            ),
                            _c("br"),
                            _c("br"),
                            _c("b-spinner", {
                              attrs: {
                                variant: "primary",
                                label: "Loading...",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isLoading
                  ? _c(
                      "span",
                      [
                        _c("b-card-title", { staticClass: "mb-0" }, [
                          _vm._v("\n          Welcome to Medloop!\n        "),
                        ]),
                        _c("b-card-text", { staticClass: "mb-2" }, [
                          _vm._v(
                            "\n          Logging into medloop is done through your Office 360 account.\n        "
                          ),
                        ]),
                        _c(
                          "div",
                          [
                            _c(
                              "b-form",
                              {
                                staticClass: "auth-login-form mt-2",
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "primary",
                                      type: "button",
                                      block: "",
                                    },
                                    on: { click: _vm.userLogin },
                                  },
                                  [
                                    _vm._v(
                                      "\n              Login\n            "
                                    ),
                                  ]
                                ),
                                _vm.user.accessToken
                                  ? _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "danger",
                                          type: "button",
                                          block: "",
                                        },
                                        on: { click: _vm.userLogout },
                                      },
                                      [
                                        _vm._v(
                                          "\n              Logout\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("b-card-text", { staticClass: "text-left mt-2" }, [
                          _vm._v(
                            "\n          Having trouble logging in? Please contact an administrator.\n        "
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }